
.tiptap p {
  @apply m-0;
}

.tiptap h1 {
  @apply text-xl m-0 mb-2;
}

.tiptap h2 {
  @apply text-lg m-0 mb-1;
}
