@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Roboto Condensed';
    font-weight: 500;
    font-style: normal;
    src: url('/public/fonts/RobotoCondensed-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Condensed';
    font-weight: 300;
    font-style: normal;
    src: url('/public/fonts/RobotoCondensed-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-style: normal;
    src: url('/public/fonts/RobotoCondensed-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Condensed';
    font-weight: 500;
    font-style: italic;
    src: url('/public/fonts/RobotoCondensed-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Condensed';
    font-weight: 300;
    font-style: italic;
    src: url('/public/fonts/RobotoCondensed-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Condensed';
    font-weight: 700;
    font-style: italic;
    src: url('/public/fonts/RobotoCondensed-BoldItalic.ttf') format('truetype');
}

/******/
@font-face {
    font-family: 'Roboto Flex';
    src: url('/public/fonts/RobotoFlex-Regular.ttf') format('truetype');
}

/******/
@font-face {
    font-family: 'Roboto';
    font-weight: 100;
    src: url('/public/fonts/Roboto-Thin.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-weight: 300;
    src: url('/public/fonts/Roboto-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    src: url('/public/fonts/Roboto-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-weight: 700;
    src: url('/public/fonts/Roboto-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Score';
    font-weight: 700;
    src: url('/public/fonts/Score.otf');
}

@font-face {
    font-family: 'Dotfont';
    font-weight: 300;
    src: url('/public/fonts/Dotfont.ttf') format('truetype');
}

@font-face {
    font-family: 'Dollydots';
    font-weight: 300;
    src: url('/public/fonts/Dollydots.ttf') format('truetype');
}

body {
    @apply m-0 p-0;
}

.custom-bg-002C8C {
    background-color: #002C8C;
}

.custom-text-a6b4d7 {
    color: #a6b4d7;
}

/* Custom drop-down menu dimensions/paddings */

.ant-dropdown-menu-item {
    padding: 0.2rem 0.75rem !important;
}

/* Custom danger (delete) button */

.ant-btn-default.custom-danger:hover:not(:disabled) {
    @apply !text-red-500 !border-red-500;
}

.ant-btn-default.custom-danger-2:not(:disabled) {
    @apply !text-red-500 !border-red-500;
}

.ant-btn-default.custom-danger-2:hover:not(:disabled) {
    @apply !text-white !border-red-500 bg-red-500;
}

.ant-btn-default.custom-danger-2:not(:disabled).active {
    @apply !text-white !border-red-500 bg-red-500;
}


/* Custom form labels */

.custom-form-label-text-size {
    font-size: 0.8rem;
}

/* Highlight focused fields */

.selector-admin-layout .ant-input:focus,
.selector-admin-layout .ant-input-focused,
.selector-admin-layout .ant-input-number-focused,
.selector-admin-layout .ant-picker-focused,
.selector-admin-layout .ant-select-focused,
.selector-admin-layout .ant-select-open,
.selector-admin-layout .ant-input-affix-wrapper-focused {
    background: #eff8ff;
}

.selector-admin-layout .ant-select-focused .ant-select-selector {
    background: #eff8ff !important;
}

.no-tab-padding .ant-tabs-tab {
    padding-top: 0px;
}

/* Remove blue background from autofilled input fields - source: ChatGPT */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.no-collapse-padding .ant-collapse-content-box {
    @apply !bg-gray-100 !p-2 !m-0;
}

/* Preflight  -- we needed to remove pre-flight from TailwindCSS because of Ant.Design, but we want to add some pieces back */
/* See here all TW preflight resets: https://unpkg.com/tailwindcss@3.4.10/src/css/preflight.css*/
*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  border-color: theme('borderColor.DEFAULT', currentColor);
}


/* This causes issues with fixed columns - they overlap with other content */
/* .no-sort-highlight td.ant-table-column-sort {
    background-color: transparent;
} */

tr:not(:hover) td.ant-table-cell {
    background-color: white !important;
}

tr:not(:hover) td.ant-table-column-sort {
    background-color: #f8f8f8 !important;
}

.no-sort-highlight tr:not(:hover) td.ant-table-column-sort {
    background-color: white !important;
}

.no-sort-highlight tr:hover td.ant-table-column-sort {
    background-color: #f8f8f8 !important;
}

td.ant-table-column-sort {
    background-color: #f8f8f8 !important;
}

.no-sort-highlight td.ant-table-column-sort {
    background-color: white !important;
}

tr.ant-table-row-selected td.ant-table-cell {
    background-color: rgb(186, 224, 255) !important;
}

.no-sort-highlight tr.ant-table-row-selected:not(:hover) td.ant-table-column-sort {
    background-color: rgb(186, 224, 255) !important;
}

.no-sort-highlight tr.ant-table-row-selected:hover td.ant-table-column-sort {
    background-color: rgb(186, 224, 255) !important;
}

.no-sort-highlight th.ant-table-column-sort.ant-table-column-has-sorters {
    background-color: rgb(250, 250, 250) !important;
}

.no-sort-highlight thead td.ant-table-cell.ant-table-selection-column {
    background-color: rgb(250, 250, 250) !important;
}

.youtubeVideoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    overflow: hidden;
}

.youtubeVideoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

td.custom-no-padding.ant-table-cell {
    padding-inline: 0px !important;
}
th.custom-no-padding.ant-table-cell {
    padding-inline: 5px !important;
}

.custom-narrow-collapse .ant-collapse-header {
    padding-right: 6px !important;
}

.custom-no-padding-card .ant-card-body {
    padding: 0 !important;
}

.custom-wide-tooltip.ant-tooltip {
    max-width: 1000px !important;
}

article * {
    /* 
    this is an attempt of hacky way to do resets for infographics,
    they use article as the root. Claases nor ids can NOT be used
    due to their higher specificity - tailwind classes would not override it
     */
    
    margin: 0; /* 1 */
    padding: 0;
    line-height: inherit; /* 2 */

    line-height: 1.5; /* 1 */
    -webkit-font-smoothing: antialiased; /* otherwise the font seems to bulky and mushy */
}

/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/
article table {
  text-indent: 0; /* 1 */
  border-color: inherit; /* 2 */
  border-collapse: collapse; /* 3 */
}
